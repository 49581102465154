<template>
    <section class="pt-4">
        <div v-for="(p,key) in productos" :key="key" class="row mx-0 mb-3 justify-center">
            <img :src="p.imagen" class="br-6 obj-cover" width="90" height="90" />
            <div class="col-6 px-2">
                <p class="nombre text-general f-12">
                    {{p.nombre}}
                </p>
                <p class="text-general2 f-12">
                    {{p.presentacion}}
                </p>
                <div class="row mx-0">
                    <div class="col-auto px-0">
                        <p class="text-general f-15 f-500 text-center">
                            {{redondearNumero(p.cantidad)}}
                        </p>
                        <p class="text-general f-12 f-300">
                            Cant. Pedida
                        </p>
                    </div>
                    <div class="col-auto px-0 ml-4">
                        <p class="text-general f-15 f-500 text-center">
                            {{redondearNumero(p.stock_disponible)}}
                        </p>
                        <p class="text-general f-12 f-300">
                            Disponible
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props:{
        productos:{
            type:Array,
            default:()=>[]
        }
    },
    data(){
        return {

        }
    }
}
</script>
